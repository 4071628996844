import React from 'react'
import {Spin} from 'antd'
function spinner() {
  return (
    <div className='spinner'>
        <Spin size='large' />
    </div>
  )
}

export default spinner